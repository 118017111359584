import {InfoIcon} from "../data/Icons.ts";

type SelectTooltipProps = {
    label: string
    text: string
}
export default function SelectTooltip(props: SelectTooltipProps)
{
    const {text, label} = props;

    return (
        <div className="SelectTooltip" tabIndex={-1}>
            <div className="SelectTooltip__label">
                {label}
                <InfoIcon/>
            </div>
            <div className="SelectTooltip__content">{text}</div>
        </div>
    )
}
